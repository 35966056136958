import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../components/PopUp.css';

function PopUp({ onClose }) {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    url_rtsp: '',
    rtsp_admin: '',
    passphrase_rtsp: '',
    ip_rtsp: '',
    port_rtsp: '',
    modelo_dvr: '',
    channel : '',
    deviceID: '',
    device_name: '',
    device_model: '',
    device_type: '',
    protocol: '',
    id_user: '',
    state: '',
    city: ''
  });

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    setUser(userData);
    setFormData(prevData => ({
      ...prevData,
      id_user: userData.id_user // Set the user ID from session
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await axios.post('https://spyskytech.net/api/register_device', formData);
      console.log('Device registered successfully:', response.data);
      onClose(); 
    } catch (error) {
      console.error('Device registration failed:', error);
    }
  };

  const renderFieldsBasedOnModel = () => {
    switch (formData.device_type) {
      case 'drone':
        return (
          <>
            <label>
              Device ID*
              <input
                type='text'
                name='deviceID'
                value={formData.deviceID}
                onChange={handleChange}
                placeholder='Enter your device ID'
                required
              />
            </label>
            <label>
              Device Name*
              <input
                type='text'
                name='device_name'
                value={formData.device_name}
                onChange={handleChange}
                placeholder='Enter your device name'
                required
              />
            </label>
            <label>
              Device Model*
              <input
                type='text'
                name='device_model'
                value={formData.device_model}
                onChange={handleChange}
                placeholder='Enter your Device Model'
                required
              />
            </label>
            <label>
              URL*
              <input
                type='text'
                name='url'
                value={formData.url}
                onChange={handleChange}
                placeholder='Enter the URL'
                required
              />
            </label>
            <label>
              City*
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                placeholder='Enter the city'
                required
              />
            </label>
            <label>
              State*
              <input
                type='text'
                name='state'
                value={formData.state}
                onChange={handleChange}
                placeholder='Enter the state'
                required
              />
            </label>
          </>
        );
      case 'cam':
        return (
          <> <label className="highlighted-label">
              DeviceName*
              <input
                type='text'
                name='device_name'
                value={formData.device_name}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label className="highlighted-label">
              URL*
              <input
                type="text"
                name="url_rtsp"
                value={formData.url_rtsp}
                onChange={handleChange}
                placeholder="URL"
                required
              />
            </label>

            <label className="highlighted-label">
              DeviceID*
              <input
                type="text"
                name="deviceID"
                value={formData.deviceID}
                onChange={handleChange}
                placeholder="DeviceID"
                required
              />
            </label>

            <label className="highlighted-label">
              Protocol*
              <select
                name="protocol"
                value={formData.protocol}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select protocol</option>
                <option value="RTSP">RTSP</option>
                <option value="SRT">SRT</option>
              </select>
            </label>
            <label>
              CamUser
              <input
                type='text'
                name='rtsp_admin'
                value={formData.rtsp_admin}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label>
              CamPassword
              <input
                type='text'
                name='passphrase_rtsp'
                value={formData.passphrase_rtsp}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label>
              CamIP
              <input
                type='text'
                name='ip_rtsp'
                value={formData.ip_rtsp}
                onChange={handleChange}
                placeholder=''
              />
            </label>
           
            <label>
              CamPort
              <input
                type='text'
                name='port_rtsp'
                value={formData.port_rtsp}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label>
              Channel
              <input
                type='text'
                name='channel'
                value={formData.channel}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label>
              DVRModel
              <input
                type='text'
                name='modelo_dvr'
                value={formData.modelo_dvr}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            
            <label>
              DeviceModel
              <input
                type='text'
                name='device_model'
                value={formData.device_model}
                onChange={handleChange}
                placeholder=''
              />
            </label>
            <label>
              State
              <input
                type='text'
                name='state'
                value={formData.state}
                onChange={handleChange}
                placeholder=''s
              />
            </label>
            <label>
              City
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                placeholder=''
              />
            </label>
          </>
        );
      case 'phone':
      case 'tablet':
      case 'bodycam':
      case 'hover':
        return (
          <>
            <label>
              Device ID*
              <input
                type='text'
                name='deviceID'
                value={formData.deviceID}
                onChange={handleChange}
                placeholder='Enter your device ID'
                required
              />
            </label>
            <label>
              Device Name*
              <input
                type='text'
                name='device_name'
                value={formData.device_name}
                onChange={handleChange}
                placeholder='Enter your device name'
                required
              />
            </label>
            <label>
              Device Model*
              <input
                type='text'
                name='device_model'
                value={formData.device_model}
                onChange={handleChange}
                placeholder='Enter your Device Model'
                required
              />
            </label>
            <label>
              URL*
              <input
                type='text'
                name='url'
                value={formData.url}
                onChange={handleChange}
                placeholder='Enter the URL'
                required
              />
            </label>
            <label>
              City*
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                placeholder='Enter the city'
                required
              />
            </label>
            <label>
              State*
              <input
                type='text'
                name='state'
                value={formData.state}
                onChange={handleChange}
                placeholder='Enter the state'
                required
              />
            </label>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='popup'>
      <div className='popup_Content'>
        <h1>Connect your device</h1>
        <p>Fill in the fields below</p>
        <form onSubmit={handleSubmit}>
          <label>
            <select name='device_type' value={formData.device_type} onChange={handleChange} required>
              <option value='' disabled>Choose your device</option>
              <option value='drone'>Aerial Robot</option>
              <option value='cam'>Camera IP</option>
              <option value='phone'>Phone</option>
              <option value='tablet'>Tablet</option>
              <option value='bodycam'>Body Cam</option>
              <option value='hover'>Hover</option>
            </select>
          </label>
          {renderFieldsBasedOnModel()}
          <button className='done_Button' type='submit'>Done</button>
          <button className='cancel_Button' type='button' onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

export default PopUp;
