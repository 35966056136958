import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import "../components/ManageDevices.css";
import { Link } from 'react-router-dom';


function ManageDevices() {
    const [devices, setDevices] = useState([]);
    const [activeUserId, setActiveUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [devicesPerPage] = useState(5);
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false); 
    const [editDeviceData, setEditDeviceData] = useState(null); 
    const [inferenceModel, setInferenceModel] = useState('eco'); 
   
    const [filterStatus, setFilterStatus] = useState('all'); // Estado inicial: 'all', 'online' ou 'offline'
    const [showDropdown, setShowDropdown] = useState(false); // Para controlar a exibição do dropdown




    useEffect(() => {
        const fetchActiveUserId = async () => {
            try {
                const userData = JSON.parse(sessionStorage.getItem('user'));
                if (userData) {
                    setActiveUserId(userData.id_user);
                }
            } catch (error) {
                console.error('Error fetching active user ID:', error);
            }
        };

        fetchActiveUserId();
    }, []);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                if (activeUserId) {
                    setLoading(true);
                    let url = `https://spyskytech.net/api/get_devices/${activeUserId}`;
    
                    if (filterStatus !== 'all') {
                        url += `?status=${filterStatus}`;
                    }
    
                    const response = await axios.get(url);
                    const sortedDevices = sortDevices(response.data);
                    setDevices(sortedDevices);
                }
            } catch (error) {
                console.error('Error fetching devices:', error);
                setErrorMessage('Failed to fetch devices.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchDevices();
    }, [activeUserId, filterStatus]);

    const sortDevices = (devices) => {
        const onlineDevices = devices.filter(device => device.status === 'online');
        const offlineDevices = devices.filter(device => device.status !== 'online');
        return [...onlineDevices, ...offlineDevices];
    };

    const handleStartStream = () => {
        setShowStreamModal(true);
    };

    const confirmStartStream = async () => {
        try {
            setLoading(true);
            const requests = selectedDevices.map(async (idDevice) => {
                const device = devices.find(d => d.id_device === idDevice);
                if (!device) return; // Verificação de segurança
    
                const requestData = {
                    id_device: idDevice,
                    lat: device.lat,
                    long: device.long,
                    inference_model: inferenceModel,
                    analytics_config: "config_none"
                };
    
                const response = await axios.post('https://spyskytech.net/api/start_stream', requestData);
                console.log(`Started streaming for device ${idDevice}. Response:`, response.data);
                return response.data;
            });
    
            const responses = await Promise.all(requests);
            console.log('All devices started streaming:', responses);
    
            const updatedDevices = devices.map(d => {
                if (selectedDevices.includes(d.id_device)) {
                    return { ...d, status: 'streaming' };
                }
                return d;
            });
            setDevices(updatedDevices);
            setSuccessMessage('Streaming started successfully.');
        } catch (error) {
            console.error('Error starting streams:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to start streaming.');
        } finally {
            setLoading(false);
            setShowStreamModal(false);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setFilterStatus(value);
        setShowDropdown(false); // Fechar o dropdown ao selecionar uma opção
    };

    const handleDisconnectDevice = async () => {
        try {
            setLoading(true);
            const requests = selectedDevices.map(async (idDevice) => {
                const response = await axios.get(`https://spyskytech.net/api/disconnect_device/${idDevice}`);
                console.log(`Disconnected device ${idDevice}. Response:`, response.data);
                return response.data;
            });

            const responses = await Promise.all(requests);
            console.log('All devices disconnected:', responses);

            const updatedDevices = devices.map(d => {
                if (selectedDevices.includes(d.id_device)) {
                    return { ...d, status: 'offline' };
                }
                return d;
            });
            setDevices(updatedDevices);
            setSuccessMessage('Devices disconnected successfully.');
        } catch (error) {
            console.error('Error disconnecting devices:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to stop streaming.');
        } finally {
            setLoading(false);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const handleEditDevice = (idDevice) => {
        const device = devices.find(d => d.id_device === idDevice);
        if (device) {
            setEditDeviceData(device);
            setShowEditModal(true);
        }
    };

    const handleUpdateDevice = async () => {
        try {
            setLoading(true);
            const response = await axios.post('https://spyskytech.net/api/update_device', editDeviceData);
            console.log('Device updated:', response.data);

            const updatedDevices = devices.map(d => (d.id_device === editDeviceData.id_device ? editDeviceData : d));
            setDevices(updatedDevices);
            setSuccessMessage('Device updated successfully.');
        } catch (error) {
            console.error('Error updating device:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to update device.');
        } finally {
            setLoading(false);
            setShowEditModal(false);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const handleDeleteDevice = async () => {
        try {
            setLoading(true);
            const requests = selectedDevices.map(async (idDevice) => {
                const response = await axios.get(`https://spyskytech.net/api/delete_device/${idDevice}`);
                console.log(`Deleted device ${idDevice}. Response:`, response.data);
                return response.data;
            });

            const responses = await Promise.all(requests);
            console.log('All devices deleted:', responses);

            const updatedDevices = devices.filter(d => !selectedDevices.includes(d.id_device));
            setDevices(updatedDevices);
            setSuccessMessage('Devices deleted successfully.');
        } catch (error) {
            console.error('Error deleting devices:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to delete devices.');
        } finally {
            setLoading(false);
            setShowDeleteModal(false);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const toggleDeviceSelection = (idDevice) => {
        if (selectedDevices.includes(idDevice)) {
            setSelectedDevices(selectedDevices.filter(deviceId => deviceId !== idDevice));
        } else {
            setSelectedDevices([...selectedDevices, idDevice]);
        }
    };

    const indexOfLastDevice = currentPage * devicesPerPage;
    const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
    const currentDevices = devices.slice(indexOfFirstDevice, indexOfLastDevice);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const renderActionButtons = () => {
        const onlineDevices = selectedDevices.filter(id => devices.find(d => d.id_device === id && d.status === 'online')).length;
        const offlineDevices = selectedDevices.filter(id => devices.find(d => d.id_device === id && d.status !== 'online')).length;

        
        if (selectedDevices.length === 1) {
            if (onlineDevices === 1) {
                return (
                    <>
                        <button className="finish_Button" onClick={handleDisconnectDevice}>Stop Streaming</button>
                        <button className="edit_Button" onClick={() => handleEditDevice(selectedDevices[0])}>Edit</button>
                        <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
                    </>
                );
            } else if (offlineDevices === 1) {
                return (
                    <>
                        <button className="start_Button" onClick={handleStartStream}>Start Streaming</button>
                        <button className="edit_Button" onClick={() => handleEditDevice(selectedDevices[0])}>Edit</button>
                        <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
                    </>
                );
            }
        }

        if (onlineDevices > 0 && offlineDevices === 0) {
            return (
                <>
                    <button className="finish_Button" onClick={handleDisconnectDevice}>Stop Streaming</button>
                    <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
                </>
            );
        } else if (offlineDevices > 0 && onlineDevices === 0) {
            return (
                <>
                    <button className="start_Button" onClick={handleStartStream}>Start Streaming</button>
                    <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
                </>
            );
        }

        return (
            <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
        );
    };

    return (
        


            <div className='card-devices'>
                <p>Últimos dispositivos cadastrados</p>
                        <table className='devices_Table'>
                            <thead>
                                <tr>
                                    
                                    <th>Device Name</th>
                                    <th>Model</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentDevices.map((device) => (
                                    <tr key={device.id_device}>
                                    
                                        <td>{device.device_name}</td>
                                        <td>{device.device_model}</td>
                                        <td className={device.status === 'online' ? 'status-online' : 'status-offline'}>
                                            {device.status === 'online' ? (
                                                <span className="online-text">• Online</span>
                                            ) : device.status === 'streaming' ? (
                                                <span className="streaming-text">Streaming</span>
                                            ) : (
                                                <span className="offline-text">• Offline</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Link to="/devices" className="data_Button">
                        Ver todos
                    </Link>  

            
        </div>
    );
}

export default ManageDevices;