import React, { useState, useEffect, useRef } from "react";
import "../components/Cameras.css";
import axios from "axios";
import { criarElementoDeVideo } from "../functions/videoUtils";
import { addCamera } from "../functions/webrtcUtils";
import { setVideoCSS } from "../functions/gridUtils";
import { initializeWebSocket } from "../functions/websocketUtils";

function Cameras() {
  const [user, setUser] = useState({});
  const [gridSize, setGridSize] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [cameras, setCameras] = useState([]);
  const [allDevicesOffline, setAllDevicesOffline] = useState(false);
  const webRTCAdaptors = useRef({});
  const socketRef = useRef(null);

  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      if (!user.id_user) return;
      const qtd = gridSize; // Define qtd based on gridSize
      try {
        const response = await axios.get(
          `https://spyskytech.net/api/get_devices/${user.id_user}?status=online&qtd=${qtd}`
        );
        const devices = response.data;
        devices.forEach(async (device) => {
          await addCamera(
            device,
            webRTCAdaptors,
            setCameras,
            criarElementoDeVideo
          );
        });
      } catch (error) {
        if(error.response.data.response === "No devices found!"){
          setAllDevicesOffline(true);
        }
        console.log(error);
      }
    };

    fetchDevices();
    socketRef.current = initializeWebSocket(user, (device) =>
      addCamera(device, webRTCAdaptors, setCameras, criarElementoDeVideo)
    );

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [user, gridSize]); // Add gridSize as a dependency

  useEffect(() => {
    cameras.forEach((camera) => {
      const video = camera.videoContainer.querySelector("video");
      if (video && video.paused) {
        video.play().catch((error) => console.error("Autoplay error:", error));
      }
    });
  }, [cameras, currentPage, gridSize]);

  const handleGridSizeChange = (size) => {
    // Ensure that setting gridSize and updating CSS styles are synchronous
    setCurrentPage(0);
    setGridSize(size);
    setVideoCSS(size);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if ((currentPage + 1) * gridSize < cameras.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = currentPage * gridSize;
  const endIndex = Math.min(startIndex + gridSize, cameras.length);

  const gridClass =
    gridSize === 1
      ? "single-camera"
      : gridSize === 4
      ? "four-camera"
      : gridSize === 9
      ? "nine-camera"
      : gridSize === 12
      ? "twelve-camera"
      : gridSize === 16
      ? "sixteen-camera"
      : "";

  const emptyGridItems = gridSize - (endIndex - startIndex);

  return (
    <div className="viewer">
      {allDevicesOffline ? (
      <div className="alert-container">
        <span className="alert">Nenhum dispositivo online encontrado.</span>
        <img src="/logo192.png" alt="SpySkyTech Logo" className="alert-image"/>
      </div>
      ) : (
        <>
          <div className={`viewer_Content ${gridClass}`}>
            <div className="grid-container">
             {cameras.slice(startIndex, endIndex).map((camera, index) => (
                <div className="grid-item" key={camera.id}>
                  <div
                    ref={(el) => {
                      if (el && !el.hasChildNodes()) {
                        el.appendChild(camera.videoContainer);
                      }
                    }}
                  />
                </div>
              ))}
              {emptyGridItems > 0 &&
                Array.from({ length: emptyGridItems }).map((_, index) => (
                  <div className="grid-item empty" key={`empty-${index}`} />
                ))}
            </div>
          </div>
        </>
      )}
      <div className="grid-controls">
        <button onClick={() => handleGridSizeChange(1)}>
          <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="34" height="24" rx="2" fill="white" />
          </svg>
        </button>
        <button onClick={() => handleGridSizeChange(4)}>
          <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="34" height="24" rx="2" fill="white" />
            <line
              x1="17.25"
              y1="24"
              x2="17.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="34.5"
              y1="12.25"
              x2="0.5"
              y2="12.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
          </svg>
        </button>
        <button onClick={() => handleGridSizeChange(9)}>
          <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="34" height="24" rx="2" fill="white" />
            <line
              x1="34.5"
              y1="16.25"
              x2="0.5"
              y2="16.25"
              stroke="#100F17"
              stroke-width="0.5"
            />
            <line
              x1="11.25"
              y1="24"
              x2="11.25"
              stroke="#100F17"
              stroke-width="0.5"
            />
            <line
              x1="34.5"
              y1="8.25"
              x2="0.5"
              y2="8.25"
              stroke="#100F17"
              stroke-width="0.5"
            />
            <line
              x1="23.25"
              y1="24"
              x2="23.25"
              stroke="#100F17"
              stroke-width="0.5"
            />
          </svg>
        </button>

        <button onClick={() => handleGridSizeChange(16)}>
          <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="34" height="24" rx="2" fill="white" />
            <line
              x1="34.5"
              y1="19.25"
              x2="0.5"
              y2="19.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="16.25"
              y1="24"
              x2="16.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="8.25"
              y1="24"
              x2="8.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="34.5094"
              y1="6.5"
              x2="0.498158"
              y2="6.5"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="34.5094"
              y1="12.5"
              x2="0.498158"
              y2="12.5"
              stroke="#100F17"
              strokeWidth="0.5"
            />
            <line
              x1="25.25"
              y1="24"
              x2="25.25"
              stroke="#100F17"
              strokeWidth="0.5"
            />
          </svg>
        </button>
      </div>
      <div className="navigation-controls">
        <button onClick={goToPreviousPage} disabled={currentPage === 0}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19M5 12L11 6M5 12L11 18"
              stroke="#FFFFFF"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button onClick={goToNextPage} disabled={startIndex + gridSize >= cameras.length}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19M19 12L13 6M19 12L13 18"
              stroke="#FFFFFF"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Cameras;
