import React, { useState, useEffect } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import "../components/Inferences.css";
import Draggable from 'react-draggable';
import axios from 'axios';



function Inferences() {
  const [activeUserId, setActiveUserId] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchActiveUserId = async () => {
        try {
            const userData = JSON.parse(sessionStorage.getItem('user'));
            if (userData) {
                setActiveUserId(userData.id_user);
            }
        } catch (error) {
            console.error('Error fetching active user ID:', error);
        }
    };

    fetchActiveUserId();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if(activeUserId){
          const response = await axios.get(`https://bifrost.spyskytech.com/${activeUserId}/grouped_analysis`);
          const responseData = response.data
          setData(responseData);
        }
      } catch (error) {
        console.error('Error fetching inferences:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up an interval to fetch data periodically
    const interval = setInterval(fetchData, 5000); // Fetch every 5 seconds
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [activeUserId]);

  const processChartData = (data) => {
    const chartData = {
      labels: [],
      quantities: []
    };
    const counts = {};
    data.devices?.forEach(device => {
      device.object_counts.forEach(countObj => {
        if (counts[countObj.class_name]) {
          counts[countObj.class_name] += countObj.count;
        } else {
          counts[countObj.class_name] = countObj.count;
        }
      });
    });

    for (const className in counts) {
      chartData.labels.push(className);
      chartData.quantities.push(counts[className]);
  }

  return chartData;
  
  };

  const chartData = data ? processChartData(data) : { labels: [], quantities: [], confidences: [] };

  const lineChartData = {
    type: 'line',
    labels: chartData.labels,
    datasets: [
      {
        label: '',
        data: chartData.quantities,
        backgroundColor: [
          '#4DD1FF',
          '#2D99FF',
          '#CBCBCB',
          '#3A4991',
          '#AA5187',
          '#C2B468',
          '#C24E34'

        ],
        borderWidth: 0,
      }
    ]
  };


  const LineChartOptions = {

    borderColor: 'green', // Cor da linha
    fill: false,
    pointStyle: 'circle',
    pointRadius: 6,
    //lineTension: 0.1,

    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        enabled: true
      }
    },
    
    scales: {
      x: {
        ticks: {
          color: 'white' // Cor das fontes da legenda
        },
        grid: {
          color: 'rgba(110, 110, 110, 1)' // Cor das fontes da legenda
        }
      },
      y: {
        ticks: {
          beginAtZero: true,
          color: 'white' // Cor das fontes da legenda
        },
        grid: {
          color: 'rgba(110, 110, 110, 1)' // Cor das fontes da legenda
        }
      }
    }
    };
  
  
  const pieChartData = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.quantities, // Use quantities for pie chart data
        backgroundColor: [
          '#4DD1FF',
          '#2D99FF',
          '#065DFF',
          '#C2B468',
          '#25347B',
          '#CBCBCB',
          '#C24E34'

        ],
        borderWidth: 0,
        width: 20,
        height: 20,
      }
    ]
  };
  const pieChartOptions = {
    plugins: {
      legend: {
        position: 'right', // Alinha a legenda à direita do gráfico
        labels: {
          font: {
            size: 18, // Tamanho da fonte das legendas
            family: 'Arial', // Fonte da legenda (opcional)
          },
          color: '#fff', // Cor da fonte das legendas
          boxWidth: 15, // Largura do quadrado de cor da legenda
          padding: 20, // Espaçamento entre os itens da legenda
          
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <div className='inferences'>
      {data ? (
        <div className='inferences_Content'>
            <Draggable>
            <div className='bar_Chart'>
              <p>Quantidade de Inferências</p>
              <Line data={lineChartData} options={LineChartOptions}/>
            </div>
            </Draggable>
            <Draggable>
            <div className='pie_Chart'>
              <p>Indentificação de Elementos</p>
              <Doughnut data={pieChartData} options={pieChartOptions} />

            </div>
            </Draggable>
          </div>
      ) : (
        <p>Loading inferences...</p>
      )}
    </div>
  );
}

export default Inferences;
